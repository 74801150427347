/*
    For any other ckeditor classes that are not included in this mapping, refer to 
    https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html#sharing-content-styles-between-frontend-and-backend
    for the inline styling
  */
const classStyleToInlineStyleMapping = {
  '<img': '<img style="border:none; width="100%""',
  'text-huge': 'font-size:1.8em;',
  'text-big': ' font-size: 1.4em;',
  'text-small': 'font-size: .85em;',
  'text-tiny': 'font-size: .7em;',
  media: 'clear: both;margin: 0.9em 0;display: block;min-width: 15em;',
  image:
    'display: table;clear: both;text-align: center;margin: 0.9em auto;min-width: 50px;',
};

const processCkEditorHTML = (html) => {
  html.replaceAll('<img', '<img width="100%"');
  for (let [key, value] of Object.entries(classStyleToInlineStyleMapping)) {
    if (value) {
      html = html.replaceAll(`class="${key}"`, `style="${value}"`);
    }
  }
  html = `<div style='font-size:16px'>${html}</div>`;
  return html;
};

export default processCkEditorHTML;
